import { LitElement as l, html as s } from "https://cdn.jsdelivr.net/gh/lit/dist@2.7.6/all/lit-all.min.js";
import { Icon as a} from "https://cdn.jsdelivr.net/npm/@appmate/wishlist@4.29.2/components/icon.js";
var i = class extends l {
  constructor() {
      super();
      this.icon = "caret"
  }
  get app() {
      if (!window.WishlistKing) throw new Error("Wishlist King SDK not initialized");
      return window.WishlistKing
  }
  render() {
      if (!this.option) return;
      let t = this.option,
          e = `options[${this.option.name}]`,
          n = `${this.app.utils.url.handlelize(this.id)}-select`;

      const disabled = t.soldoutValues.includes(t.selectedValue) || t.unavailableValues.includes(t.selectedValue);
      return s`
    <div class="wk-option-select">
      <label class="wk-label" for=${n}>${t.name}</label>
      <div class="wk-control">
        <select id=${n} class="wk-input" name=${e}>
          ${!t.selectedValue&&this.defaultText?s`<option disabled selected>${this.defaultText}</option>`:void 0}
          ${t.values.map(o=>s`
              <option
                value=${o}
                ?selected=${o===t.selectedValue}
              >
                ${o}${t.soldoutValues.includes(o) || t.unavailableValues.includes(o) ? ' - Sold Out' : ''}
              </option>
            `)}
        </select>
        <div class="wk-content">
          <div class="wk-text">
            ${t.selectedValue||this.defaultText}${disabled ? ' - Sold Out' : ''}
          </div>
          <wk-icon class="wk-icon" icon=${this.icon}></wk-icon>
        </div>
      </div>
    </div>
  `
  }
  updated(t) {
      var e;
      super.updated(t), t.has("option") && this.classList.toggle("wk-single-option", ((e = this.option) == null ? void 0 : e.values.length) === 1)
  }
  createRenderRoot() {
      return this
  }
};
i.properties = {
  icon: {
      type: String
  },
  option: {
      type: Object
  },
  defaultText: {
      type: String
  }
};
a.addIcons({
  caret: `
  <svg aria-hidden="true" focusable="false" role="presentation" viewBox="0 0 24 24">
    <path d="M6 9l6 6 6-6" />
  </svg>
`
});
customElements.get("wk-option-select") || customElements.define("wk-option-select", i);
export {
  i as OptionSelect
};